import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import metaImage from '../../static/default_social_image.png';

function Seo({ description = '', lang = 'en', title = '' }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            canonicalUrl
          }
        }
      }
    `
  );

  title = title || site.siteMetadata.title;
  const keywords = site.siteMetadata.keywords;
  const twitter = site.siteMetadata.twitter;
  const canonicalUrl = site.siteMetadata.canonicalUrl;
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet htmlAttributes={{ lang }}>
      <link rel="canonical" href={canonicalUrl} />
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={keywords} />
      <meta name="image" content={`${canonicalUrl}${metaImage}`} />

      {/* OpenGraph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={`${canonicalUrl}${metaImage}`} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:locale" content="en_UK" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={`${canonicalUrl}${metaImage}`} />
      <meta name="twitter:image:alt" content={metaDescription} />
    </Helmet>
  );
}

export default Seo;
